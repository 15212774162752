import React from 'react';
import ReactDOM from 'react-dom';
import { ByFelizApp } from './ByFelizApp';
import './styles.css';
import 'antd/dist/antd.css';

ReactDOM.render(
  
    <ByFelizApp />,
  document.getElementById('root')
);

